.exportBtn,
.importBtn,
.savedBtn,
.whiteBtn,
.sortBtn {
    display: flex !important;
    background-color: #FFF !important;
    align-items: center !important;
    border: 3px #EFEFEF solid !important;
    padding: 10px 20px;
    color: #1A1D1F;
    border-radius: 10px;
    margin: 0px 5px;
    font-weight: 900;
    height: 50px;
    cursor: pointer;
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    margin-top: 25px;
}

.buttonGroupCtr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    margin-top: 20px;
}

.clearBtn {
    display: flex !important;
    background-color: #FFF !important;
    align-items: center !important;
    border: 3px #80808036 solid !important;
    padding: 10px 20px;
    border-radius: 10px;
    margin: 0px 5px;
    font-weight: 900;
    height: 50px;
    cursor: pointer;
}

.exportBtn img,
.importBtn img,
.savedBtn img {
    padding-right: 10px;
}

.plusBlueIcon {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    background-color: #2A85FF;
    font-style: normal;
    border: 3px #2A85FF solid;
    border-radius: 12px;
    margin: 0px 5px;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: -0.01em;
    color: #FCFCFC;
}
.plusIcon {
    padding-right: 10px;
}

.cutomer_table_search label .searchIcon {
    position: absolute;
    top: -3px;
    left: 6px;
    font-size: 1.6em;
}

.importFileButton {
    opacity: 0;
    position: absolute;
    width: 98px;
    padding: 10px 20px 10px 20px;
    margin: 0px;
    cursor: pointer !important;
}

.blueBtn {
    padding: 12px 20px;
    background-color: #2A85FF;
    font-weight: 900;
    color: white;
    height: 50px;
    border: 3px #2A85FF solid;
    border-radius: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.greenBtn {
    padding: 12px 20px;
    background-color: green;
    font-weight: 900;
    color: white;
    height: 50px;
    border: 3px green solid;
    border-radius: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.redBtn {
    padding: 12px 20px;
    background-color: red;
    font-weight: 900;
    color: white;
    height: 50px;
    border: 3px red solid;
    border-radius: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.yellowBtn {
    padding: 12px 20px;
    background-color: #a3a332;
    font-weight: 900;
    color: #000;
    height: 50px;
    border: 3px #a3a332 solid;
    border-radius: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.CSVLink {
    display: flex;
    align-items: center;
    color: #000;
}

.container_top_btns {
    display: flex;
}

.filter_dropdown {
    display: flex;
    margin-right: 5px;
    width: 38%;
    height: 44px;
    align-items: center;
    justify-content: center;
    border-right: 2px solid #f1f1f1;
}
.more_filter {
    display: flex;
    margin-right: 5px;
    width: 38%;
    height: 44px;
    align-items: center;
    justify-content: center;
}
.cheveron_icon {
    margin: 0 10px;
}





@media (max-width : 780px) {

    .button_in_center {
        display: flex;
        justify-content: center;
    }
    .blueBtn {
        padding: 5px 10px;
        background-color: #2A85FF;
        font-weight: 900;
        color: white;
        height: 38px;
        border: 3px #2A85FF solid;
        border-radius: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .exportBtn, .importBtn, .savedBtn, .whiteBtn, .sortBtn {
        display: flex !important;
        background-color: #FFF !important;
        align-items: center !important;
        border: 3px #EFEFEF solid !important;
        padding: 6px 10px;
        color: #1A1D1F;
        border-radius: 10px;
        margin: 0px 5px;
        font-weight: 900;
        height: 40px;
        cursor: pointer;
    }
    .plusBlueIcon {
        padding: 8px !important;
    }
}

@media (max-width : 480px) {
    .blueBtn {
        padding: 5px 10px;
        background-color: #2A85FF;
        font-weight: 900;
        color: white;
        height: 38px;
        border: 3px #2A85FF solid;
        border-radius: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .exportBtn, .importBtn, .savedBtn, .whiteBtn, .sortBtn {
        display: flex !important;
        background-color: #FFF !important;
        align-items: center !important;
        border: 3px #EFEFEF solid !important;
        padding: 6px 10px;
        color: #1A1D1F;
        border-radius: 10px;
        margin: 0px 5px;
        font-weight: 900;
        height: 40px;
        cursor: pointer;
    }
    .plusBlueIcon {
        padding: 8px !important;
    }
    .clearBtn {
        display: flex !important;
        background-color: #FFF !important;
        align-items: center !important;
        border: 3px #80808036 solid !important;
        padding: 5px 10px;
        border-radius: 10px;
        margin: 0px 5px;
        font-weight: 900;
        height: 40px;
        cursor: pointer;
    }
    .redBtn {
        height: 40px !important;
    }
}
