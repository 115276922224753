@media (max-width: 768px) {

    .m800_settingsNavigation {
        width: auto; 
        height: 150px;
    }

    .m800_settings_container {
        flex-direction: column !important;
    }

    .m800_settingsNavigation ul {
        display: flex;
        flex-wrap: wrap;
    }

    .m800_settingsNavigation ul li {
        margin: 2px;
    }
    .m800_settingsContent {
        width: 100%;
        padding: 20px 30px;
    }

}




