.fs20 {
    font-size: 20px;
}
.fs10 {
    font-size: 10px;
}

.ctr {
    text-align: center !important;
}

.fred {
    color: red !important;
}

.success_clr {
    color: #07bc0c !important;
}