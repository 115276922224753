// .a2z_mainContent {
//     position: absolute;
//     width: calc(100% - 340px);
//     left: 340px;
//     min-height: 200vh;
//     background: var(--gray);
//     transition: 0.5s;
// }
.mobile_view_topnav_title {
    display: none;
}
.a2z_mainContent {
    position: absolute;
    width: calc(100% - 220px);
    left: 220px;
    min-height: 200vh;
    background: var(--mainContent-bg-clr);
    color: var(--mainContent-clr);
    transition: 0.5s;
}

.a2z_mainContent.active {
    width: calc(100% - 80px);
    left: 80px;
}

.a2z_header {
    position: sticky;
    top: 0px;
    z-index: 10;
    background: var(--topnav-bg-clr);
    color: var(--topnav-clr);
    border-left: 3px var(--topnab-br-clr) solid;
}

.s200_topbar {
    width: 100%;
    // height: 96px;
    height:60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-left: 1px gray;
    padding: 0 10px;
}

.s200_toggle {
    position: relative;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2.5em;
    cursor: pointer;
    // flex-grow: 1
}

.s200_search {
    // position: relative;
    // width: 760px;
    // margin: 0 10px;
    // flex-grow: 7;
    margin-left: 15%;
}

.s200_search label {
    position: relative;
    width: 100%;
}

.s200_search label input {
    // width: 32%;
    height: 48px;
    border-radius: 30px;
    padding: 5px 20px;
    padding-left: 35px;
    // padding-right: 60px;
    font-size: 18px;
    outline: none;
    background: var(--g-input-br-clr);
    border: 3px solid var(--border-clr);
    color: var(--text-clr) !important;
}

.s200_search label .icon {
    position: absolute;
    top: -3px;
    left: 10px;
    font-size: 1.6em;
}

.s200_search label .sicon {
    position: absolute;
    top: -10px;
    right: 8px;
    font-size: 1.2em;
    background-color: var(--topnav-bg-clr) !important;
    color: var(--topnav-clr) !important;
    padding: 8px;
    border-radius: 5px;
}

.s200_chatbox,
.s200_notifications {
    font-size: 2.0em;
    padding-right: 2px;
}

.menu_icon {
    filter: var(--toggle-icon-clr) !important;
}

.s200_user {
    position: relative;
    width: 60px;
    height: 60px;
    // margin-right: 20px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}

.s200_user img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}




// menu 

.profileImg {
    position: relative;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
}

.action {
    position: sticky;
    right: 20px;
    // top: 15px;
}

.action .menu {
    background-color: var(--topnav-bg-clr);
    color: var(--topnav-clr);
    box-sizing: border-box;
    border-radius: 15px;
    padding: 10px 20px;
    position: absolute;
    right: 10px;
    width: 200px;
    transition: 0.5s;
    top: 76px !important;
    visibility: hidden;
    opacity: 0;
    box-shadow: 0 2px 5px var(--topnav-clr);

}

.action .menu.active {
    opacity: 1;
    top: 75px;
    visibility: visible;
}

.action .menu::before {
    background-color: var(--topnav-bg-clr);
    content: '';
    height: 20px;
    position: absolute;
    right: 35px;
    transform: rotate(45deg);
    top: -8px;
    width: 20px;
}

.action .menu ul li {
    align-items: center;
    border-top: 1px solid var(--bg-clr);
    display: flex;
    justify-content: left;
    list-style: none;
    padding: 10px 0px;
}

.action .menu ul li:hover {
    background:var(--hover-bg-clr);
    color: #000;
    border-radius: 4px;

}

.action .menu ul li img {
    max-width: 20px;
    margin-right: 10px;
    opacity: 0.5;
    transition: 0.5s
}

.action .menu ul li a {
    display: inline-block;
    color: var(--topnav-clr);
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    /* transition: 0.5s; */
    text-align: center;
    width: 100%;
}

.action .menu ul li:hover img {
    opacity: 1;
}

.action .menu ul li:hover a {
    color: #2A85FF;
    font-weight: 500;
}

.action .menu .viewUser img {
    display: flex;
}

.role_box {
    display: none;
}




