.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.tooltip-text {
    visibility: hidden;
    opacity: 0;
    z-index: 999 !important;
    position: absolute;
    bottom: 19%;
    height: 30px;
    align-items: center;
    display: flex;
    left: 177%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 18px;
    border-radius: 4px;
    font-size: 14px;
    transition: visibility 0s, opacity 0.3s ease-in-out;
    white-space: nowrap;
    white-space: nowrap;
}

.tooltip-text::after {
    content: "";
    position: absolute;
    top: 35%;
    left: -3%;
    rotate: 90deg;
    margin-left: -6px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
}