.m800_settings_container {
    margin: 30px 0px;
    border-radius: 8px;
    background-color: var(--settings-bg-clr);
    color: var(--settings-clr);
    display: flex;
}

.settings_desktop_view {
    display: block;
}
.settings_mobile_view {
    display: none;
}

.m800_settingsNavigation {
    position: relative;
    // width: 300px;
    width: 190px;
    height: 700px;
    background-color: var(--settings-bg-clr);
    color: var(--settings-clr);
    transition: 0.5s;
    overflow: hidden;
    border-radius: 8px;
    margin-top: 10px;
}

.m800_settingsNavigation.active {
    width: 80px;
}

.m800_settingsNavigation ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 20px;
}

.m800_settingsNavigation ul li {
    position: relative;
    color: #6F767E;
    cursor: pointer;
    // width: 190px;
    width: 165px;
    list-style: none;
    border-radius: 8px;
    // width: 280px;
    height: 40px;
}

.m800_li_Active {
    background: var(--hover-bg-clr);
    border-radius: 8px;
    border-radius: 10px;
    margin: 3px 0;
    color: var(--active-text-clr) !important;
}

.m800_settingsNavigation ul li:hover,
.m800_settingsNavigation ul li.hovered {
    background: var(--hover-bg-clr);
    color: var(--hover-clr);
    border-radius: 8px;
}

.m800_settingsNavigation ul li a {
    position: relative;
    display: block;
    width: 100%;
    display: flex;
    text-decoration: none;
    color: var(--black2);
    font-weight: 900;
}

.m800_settingsNavigation ul li:hover a,
.m800_settingsNavigation ul li.hovered a {
    color: #2A85FF;
}

.m800_sideTab_title {
    position: relative;
    display: block;
    padding: 0px 15px;
    height: 40px;
    line-height: 40px;
    text-align: start;
    white-space: nowrap;
}

/* settings Common styles*/

.m800_title_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.m800_settingsContent {
    // width: 74%;
    width: 88%;
    padding: 20px 30px;
}

.m800_settingsTitle {
    height: 32px;
    font-size: 20px;
    font-weight: 900;
    border-left: 4px #FFD88D solid;
    padding: 6px 0px 6px 15px;
    margin:15px 0 ;
}



