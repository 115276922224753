.df {
    display: flex;
}

.df_alc_jcs {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
}

.df_alc_20 {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: flex-start;
}
.dfac {
    display: flex;
    align-items: center;
}
.dfacjc {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.dfacjc_g10 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.df_fdc_g10 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.df_fdc_jc_ac {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.df_fdc_g5 {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-end;
}
.df_aie_je_g5 {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    align-items: center;
}
.df_fdc_aie_je_g5 {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    gap: 5px;
    align-items: center !important;
}

.df_alc_jcsb {
    display: flex;
    align-items: center;
    justify-content: space-between;
}