// margin top 
.mt20 {
    margin-top: 20px;
    display: block;
}
.mt10 {
    margin-top: 10px;
    display: block;
}


// margin right  

.mr5 {
    margin-right: 5px !important;
}
.mr10 {
    margin-right: 10px !important;
}
.mr20 {
    margin-right: 20px;
}


// margin bottom

.mb10 {
    margin-bottom: 10px !important;
}
.mb20 {
    margin-bottom: 20px !important;
}
// margin left


.mlr20 {
    margin: 0 20px;
}
.ml10 {
    margin-left: 10px !important;
}

.ml20 {
    margin-left: 20px;
}

.lh30 {
    line-height: 30px;
}


// padding 

.p40 {
    padding: 40px;
}