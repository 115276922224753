.venue_list {
    display: flex;
    padding: 10px 0px;
    align-items: center;
    justify-content: normal;
    width: 100%;
    flex-wrap: wrap;
}
.venue_avatar {
    position: relative;
    width: 340px;
    border-radius: 10px;
    height: 200px;
    border: 5px solid #FFF;
    overflow: hidden;
    cursor: pointer;
}
.venue_title {
    font-weight: 600;
    font-size: 16px;
    padding: 10px;
    width: 100%;
    line-height: 15px;
    color: #FFF;
}
.location_logo {
    margin: 0 5px;
}