.commonheader {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 10px;
    letter-spacing: -0.03em;
    color: var(--common-header-clr);
}
.subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 34px;
    letter-spacing: -0.015em;
    color: var(--common-header-clr);
}