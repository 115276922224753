@media (max-width: 480px) {

    .desktop_table_view {
        display: none;
    }
    
   .mobile_table_view {
    display: block;
    background: var(--table-bg-clr);
    border-radius: 10px;
   }
   .table_items {
    border-bottom:2px solid var(--border-clr) ;
    padding: 10px;
   }
   .table_item {
    margin: 5px 0;
   }
   .table_labels {
    font-size: 10px;
    color:#6F767E;
    text-transform: uppercase;
   }
   .table_2_items {
    display: flex;
    align-items: center;
    justify-content: space-between;
   }

   .right_item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
   }
   .mobile_pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 10px 15px !important;
    color: #808080;
    font-size: 16px;
   }
   .mobile_current_page {
    display: flex;
    align-items: center;
   }
   .mobile_view_empty_table {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
   }


}