.mobile_view_btns {
    display: none;
}



@media (max-width: 480px) {
    .mobile_view_btns {
        display: block;
    }
    .desktop_view_btns {
        display: none;
    }
    .exportBtn img {
        padding-right: 0 !important;
    }
    .s400_card {
        padding: 5px 10px !important;
    }
}