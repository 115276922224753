// Common inputs 

.activeInput {
    background: var(--bg-clr) !important;
    color: var(--text-clr) !important;
    border: 3px solid var(--bg-clr) !important;
    box-sizing: border-box !important;
    border-radius: 12px !important;
    padding: 12px !important;
    caret-color: #2A85FF !important;
    margin-top: 10px !important;
    
}

.readOnlyInput {
    background: var(--read-only-input-bg-clr) !important;
    border: 3px solid var(--input-border-clr) !important;
    border-radius: 12px !important;
    color: var(--text-clr) !important;
    font-weight: 900 !important;
    padding: 12px !important;
    margin-top: 10px !important;
    cursor: not-allowed !important;
}

.activeInputGray {
    background: var(--bg-clr) !important;
    color: var(--text-clr) !important;
    border: 3px solid var(--bg-clr) !important;
    box-sizing: border-box !important;
    border-radius: 12px !important;
    padding: 12px !important;
    caret-color: #2A85FF !important;
    margin-top: 10px !important;
}

.activeDropDownGray {
    background: #F4F4F4 !important;
    border: 2px solid #F4F4F4 !important;
    box-sizing: border-box !important;
    border-radius: 12px !important;
    padding: 12px !important;
    caret-color: #2A85FF !important;
    margin-top: 10px !important;
    font-weight: 600 !important;
}
.smallDropDownGray {
    background: white !important;
    border: 2px solid #F4F4F4 !important;
    box-sizing: border-box !important;
    border-radius: 12px !important;
    padding: 19px !important;
    caret-color: #2A85FF !important;
    margin: 10px !important;
    font-weight: 600 !important;
    width: 200px;
}

// common lables with icons

.labelIcon {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 900;
    gap: 2px;
}

.modalHeadings {
    font-weight: 700;
    display: block;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #33383F;
    margin-top:20px ;
    margin-bottom: 20px;
}

.filledI {
    margin-left: 5px;
}

.error {
    color: red;
    display: block;
}