/* src/Card.css */
.card {
  width: 350px;
  border: 1px solid transparent;
  border-radius: 8px;
  overflow: hidden;
  margin: 15px 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.card-image-container {
  position: relative;
}

.card-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #009688;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
}

.image-overlay {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8em;
}

.card-content {
  padding: 16px;
  text-align: left;
}

.card-title {
  margin: 0 0 8px 0;
  font-size: 14px;
}

.location {
  color: #777;
  margin-bottom: 12px;
}

.price {
  color: #f0394d;
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 8px;
}

.rating {
  color: #ffa726;
  margin-bottom: 16px;
  font-size: 0.9em;
}

.info {
  margin-bottom: 16px;
  font-size: 0.9em;
  color: #555;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.availability-button, .contact-button {
  width: 48%;
  padding: 8px 0;
  border-radius: 4px;
  border: none;
  font-size: 0.9em;
  cursor: pointer;
}

.availability-button {
  background-color: #fff;
  color: #009688;
  border: 1px solid #009688;
}

.contact-button {
  color: #f0394d;
  border: 1px solid #f0394d;
  background-color: #fff;
}

.availability-button:hover {
  background-color: #009688;
  color: white;
}
.contact-button:hover {
  background-color: #f0394d;
  color: white;
}

.contact-button:hover {
  background-color: #f0394d;
}

.app {
  text-align: center;
  padding: 32px;
}

.card-container {
  display: flex;
  justify-content:flex-start;
  flex-wrap: wrap;
}
