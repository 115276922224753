.s400_card {
    display: flex;
    flex-direction: column;
    padding: 15px 25px;
    margin: 10px 0px;
    background: var(--card-bg-clr);
    color: var(--card-clr);
    border-radius: 10px;
    width: 100%;
    // height: 100%;
}

.s400_card_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.s400_card_title {
    font-size: 20px;
    font-weight: 900;
    border-left: 4px #f0394d solid;
    padding: 6px 0px 6px 15px;
    margin: 5px 25px 5px 5px;
    border-radius: 1px;
    margin-top: 5px !important;
}
.s400_card_title_green {
    font-size: 20px;
    font-weight: 900;
    border-left: 4px #f0394d solid;
    padding: 6px 0px 6px 15px;
    margin: 5px 25px 5px 5px;
    border-radius: 1px;
    margin-top: 5px !important;
}
.s400_card_title_blue {
    font-size: 20px;
    font-weight: 900;
    border-left: 4px #f0394d solid;
    padding: 6px 0px 6px 15px;
    margin: 5px 25px 5px 5px;
    border-radius: 1px;
    margin-top: 5px !important;
}



.s400_card_header_dropdown {
    align-items: flex-end;
}

.s400_grayDropDown {
    border: 2px #d3d3d3 solid !important;
    border-radius: 10px !important;
}
.ui  ui.selection.dropdow {
    min-width: 8em !important;
}