.m801_basics_row {
    display: flex;
    align-items: center;
}

.basicsBtns {
    margin: 0px 15px;
}

.m801_profilePic {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 30px;
}

.m801_basicForm {
    padding: 20px 0px;
}

.basicBtns {
    margin: 20px 0px;
}

.uploadProfileImg {
    opacity: 0;
    position: absolute;
    width: 180px;
    padding: 10px 20px 10px 20px;
    margin: 0px;
    cursor: pointer !important;
}




