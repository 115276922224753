@media (max-width: 480px) {

    .mobile_view_topnav_title {
        text-transform: uppercase;
        font-weight: 900;
        display: block;
        text-align: center;
    }
    .s200_toggle {
        width: 40px !important;
    }

    
    .a2z_mainContent.active {
        // width: calc(100% - 220px) !important;
        // left: 220px !important;
        filter: blur(5px) grayscale(.7);
        background: var(--sidenav-blur-bg-clr);

    }
    .a2z_mainContent {
        width: 100% !important;
        left: 0 !important;
    }
    .a2z_sidenav.active {
        width: 62% !important;
        transition: 0.5s !important;
    }
    .a2z_sidenav {
        width: 0px;
        transition: 0.5s;
    }

    .s200_search {
        display: none !important;
    }
    .s200_user {
        margin-right: 10px;
    }
    .action {
        right: 0;
    }
    .action .menu::before {
        right: 20px;
    }
    .a2z_header {
        border-left: 0px var(--topnab-br-clr) solid;
    }
    
}