.light_theme {
  
    
    --sidenav-clr : #FFF !important;

    --sidenav-blur-bg-clr : #FFF !important;
    
    --sidenav-bg-clr : #f0394d !important;

    --topnav-bg-clr : #f0394d !important;

    --topnab-br-clr : #FFF !important;
    
    --topnav-clr : #FFF !important;

    --mainContent-bg-clr : #F1F1F1;

    --mainContent-clr : #000;
    
    --switchingContent-bg-clr : #FFF !important;
    
    --switchingContent-clr : #000 !important;
    
    --card-bg-clr : #FFF !important;
    
    --card-clr : #000 !important;
    
    --modal-blur-clr : #FFF !important;
    
    --modal-bg-clr : #FFF !important;
    
    --modal-clr : #000 !important;

    --common-header-clr : #000 !important;

    --card-bg-clr : #FFF !important;

    --card-clr : #000 !important;  

    --settings-bg-clr : #FFF !important;

    --settings-clr : #000 !important;
    
    --active-text-clr : #2A85FF !important; 

    --hover-bg-clr : #FFF !important;

    --dl-hover-bg-clr : #0000001f !important;

    --hover-clr : #000 !important;

    --input-bg-clr : #FFFFFF !important;

    --input-border-clr : #dbdbdb !important;

    --g-input-br-clr : #FFFFFF !important;

    --read-only-input-bg-clr : #FFF !important;

    --text-clr : #000 !important;

    --bg-clr : #f0394d !important;

    --active-bg-clr : #F3F3F3 !important;

    --table-bg-clr : #FFF !important;
    
    --table-clr : #000 !important;  
    
    --border-clr : #F4F4F4 !important;

    --table-header-clr : #6F767E !important;

    --dropdown-text-color : #FFF !important;

    --icons-clr : brightness(0) ;

    --icons-hover-clr : brightness(0) ;

    --icons-active-clr : brightness(100) ;

    --action-bg--clr : #3f3f3f ;

    --action-icons--clr : brightness(100) ;

    --copy-icons-hover : brightness(100) ;

    --toggle-icon-clr : invert(1) ;

    --modal-text-clr : #3a3a3a !important;

    --product-table-border : #FFF;

    --product-table-bg : #F4F4F4;

    --subproduct-table-hover-bg : #d5d5d5bb;

    --details_view_tab_bg : #F4F4F4 ; 

    --details_view_active_tab_bg : #F6F6F6 ; 

    --delete-modal-icon-bg : #e31919 ;


}
    
.dark_theme {
    
    --sidenav-bg-clr : #000 !important;
    
    --sidenav-clr : #FFF !important;

    --sidenav-blur-bg-clr : #3a3a3a91 !important;
    
    --topnav-bg-clr : #000 !important;

    --topnab-br-clr : #3a3a3a !important;
    
    --topnav-clr : #FFF !important;
 
    --mainContent-bg-clr : #3a3a3a;

    --mainContent-clr : #FFF;
       
    --switchingContent-bg-clr : #000 !important;
    
    --switchingContent-clr : #FFF !important;
    
    --card-bg-clr : #000 !important;
    
    --card-clr : #FFF !important;
    
    --modal-blur-clr : #000 !important;
    
    --modal-bg-clr : #000 !important;
    
    --modal-clr : #FFF !important;

    --common-header-clr : #FFF !important;

    --card-bg-clr : #000 !important;

    --card-clr : #FFF !important;

    --settings-bg-clr : #000 !important;

    --settings-clr : #FFF !important;

    --active-text-clr : #2A85FF !important; 

    --hover-bg-clr : #3a3a3a91 !important;
    
    --dl-hover-bg-clr : #3a3a3a91 !important;

    --hover-clr : #FFF !important;

    --input-bg-clr : #686868 !important;

    --input-border-clr : #686868 !important;

    --g-input-br-clr : #3a3a3a !important;

    --read-only-input-bg-clr : #000 !important;

    --text-clr : #FFF !important;

    --bg-clr : #3a3a3a !important;

    --table-bg-clr : #000 !important;

    --table-clr : #FFF !important;

    --table-header-clr : #6F767E !important;

    --active-bg-clr : #5a5a5a !important;

    --border-clr : #3a3a3a !important;

    --dropdown-text-color : #000 !important;

    --icons-clr : brightness(1.2) ;

    --icons-hover-clr : brightness(1.8) ;

    --icons-active-clr : brightness(100) ;

    --action-icons--clr : brightness(100) ;

    --action-bg--clr : #252323 ;

    --copy-icons-hover : brightness(0) ;

    --toggle-icon-clr : invert(1) ;

    --modal-text-clr : #909090 !important;

    --product-table-border : #000;

    --product-table-bg : #3a3a3a91;

    --details_view_tab_bg : #3a3a3a91 ; 

    --details_view_active_tab_bg : #3a3a3a99 ; 

    --delete-modal-icon-bg : #e31919 ;


}