@media (max-width: 768px) {
    .overview_card {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .dashboard_grid-container {
        display: grid;
        grid-template-columns: auto;
        gap: 10px;
    }
    .m200_dashboard_container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex-wrap: wrap;
    }
    .m200_left_Container {
        width: 100%;
    }
    .m200_right_Container {
        width: 100%;
    }
    .overview_card {
        margin: 4px 5px;
        padding: 10px;
    }
    .overview_card h5 {
        flex-wrap: wrap;
        width: auto !important;
    }
    .overviewTitle {
        align-items: flex-start;
    }
    
}