
@media (max-width : 780px) {
    .m801_basics_row {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }
    .m801_profilePic {
        margin-right: 0;
    }
}