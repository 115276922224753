@media (max-width:480px) {

    .settings_desktop_view {
        display: none;
    }
    .settings_mobile_view {
        display: block;
    }

    .m800_settingsNavigation {
        width: auto; 
        height: 200px;
    }
    .m800_settingsContent {
        width: 100%;
        padding: 20px 30px;
    }
    
    .mobile_tab {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        background: var(--settings-bg-clr);
        margin: 5px 0px;
        border-radius: 8px;
        padding: 0px 15px;
        height: 50px;
    }
    .mobile_settings_container {
        margin-top: 30px;
    }
    .m800_container {
        margin-top: 10px;

    }
    .MV_switch_content {
        background: var(--settings-bg-clr);
        padding: 10px 15px;
        margin: 15px 0px;
        border-radius: 8px;

    }

}