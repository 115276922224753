.digital_invitations_container {
    margin-top: 30px;
}

@media (max-width: 480px) {
    .digital_invitations_container {
        margin-top: 30px;
        display: flex;
        align-items: center;
    }

    .flip-card:hover .flip-imgBox {
        transform: rotate(-180deg) !important;
    }

    .flip-card:hover {
        transform: none !important;
    }
}

// flip card styles 

.flip-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.flip-card {
    position: relative;
    width: 300px;
    height: 400px;
    // background: #f0394d ;
    // color: #FFF;
    transform-style: preserve-3d;
    transform: perspective(1000px);
    box-shadow: 10px 20px 40px rgba(0, 0, 0, 0.25);
    transition: 1s;
    border-radius: 20px;
}

.flip-card:hover {
    transform: translateX(50%);
}

.flip-card .flip-imgBox {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    transform-origin: left;
    transform-style: preserve-3d;
    // background: #000;
    transition: 1s;
    box-shadow: 10px 20px 40px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}


.flip-card:hover .flip-imgBox {
    transform: rotateY(-180deg);
}

.flip-card .flip-imgBox img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    border-radius: 20px;
    border: 10px solid #f1f1f1;
}

.flip-card .flip-imgBox img:nth-child(2) {
    transform: rotateY(180deg);
}

.flip-card .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
}

.flip-card .details .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.flip-card .details .content h2 {
    text-align: center;
    font-weight: 700;
    line-height: 1em;
}

.flip-card .details .content h2 span {
    color: #e21212;
    font-size: 0.8em;
}

.flip-card .details .content .social-icons {
    position: relative;
    display: flex;
    margin-top: 10px;
}

.flip-card .details .content .social-icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    background: #333;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 5px;
    font-size: 18px;
    transition: 0.2s;
}

.flip-card .details .content .social-icons a:hover {
    background: #e21212;
}

.flip-card-title {
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
}
.wedding-invite-name {
    font-size: 14px;
}
.wedding-date {
    font-family: cursive;
    font-weight: 900;
}
// .swiper-pics {
//     width: 95%;
//     display: flex;
//     margin: 20px 10px;
//     align-items: center;
//     justify-content: center;
// }
.digital-invite-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.digital-invitation-container {
    display: flex;
    flex-wrap: wrap;
}