.tabs_container {
    display: flex;
    margin: 10px 0;
    padding-bottom: 10px;
    width: 100%;
    border-bottom: 3px solid var(--bg-clr);
    .stab {
        display: block;
        padding: 15px 20px;
        background: var(--bg-clr);
        margin: 5px 10px; 
        border-radius: 10px;
        cursor: pointer;
    }   
    .stabactive {
        display: block;
        padding: 15px 20px;
        background: var(--active-bg-clr);
        font-weight: bold;
        margin: 5px 10px; 
        border-radius: 10px;
        cursor: pointer;
    }   
    .stab:hover {
        background: var(--active-bg-clr);
        font-weight: bold;
    }   
}
.filter_container {
    .search_sort_container {
        display: flex;
        margin: 10px 0px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .searchbar {
            position: relative;
            margin: 5px;
            flex-grow: 7;
            width: 35%;
        }
        
        .searchbar label {
            position: relative;
            width: 100%;
        }
        
        .searchbar label input {
            width: 100%;
            height: 49px;
            border-radius: 10px;
            padding: 5px 20px;
            padding-left: 35px;
            font-size: 18px;
            outline: none;
            background: var(--bg-clr);
            color: var(--text-clr);
            border: 3px solid var(--border-clr) !important;
        }
        
        .searchbar label .searchIcon {
            position: absolute;
            top: -3px;
            left: 6px;
            font-size: 1.6em;
        }
    }
    .advance_search_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 15px 0;
        .advanceSearchBtn {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: -0.01em;
            color: #2A85FF;
            margin: 0px 5px;
            
        }
        
        .filledI {
            margin-left: 5px;
        }
       
    }
}
.activeInputGray {
    background: #F4F4F4 !important;
    border: 2px solid #F4F4F4 !important;
    box-sizing: border-box !important;
    border-radius: 12px !important;
    padding: 12px !important;
    caret-color: #2A85FF !important;
    margin-top: 10px !important;
}
.advanceSearchOptions {
    margin: 20px 5px;
}

.cheveron_img {
    border-right: 2px #80808036 solid !important;
}

.filter_tab_container {
    display: flex !important;
    background-color: #FFF !important;
    align-items: center !important;
    border: 2px #80808036 solid !important;
    padding: 10px 20px;
    border-radius: 10px;
    margin: 0px 5px;
    font-weight: 600;
    height: 50px;
    width: 75%;
}

.filledI_right {
    rotate: 270deg;
    margin-left: 5px;
    cursor: pointer;
}
.filledI_down {
    rotate: 0deg !important;
    margin-left: 5px;
    cursor: pointer;
}
.filledI_up {
    rotate: 180deg !important;
    margin-left: 5px;
    cursor: pointer;
}

@media (max-width: 480px) {

.filter_container .search_sort_container .searchbar label input {
    height: 40px !important;
}
.tabs_container {
    margin: 0px;
    height: 60px;
}
.tabs_container .stab, .tabs_container .stabactive {
    padding: 8px 20px !important;
}

}
