.a2w_main_root {
    position: relative;
    width: 100%;
}

.a2z_switchingContent {
    padding: 30px 40px;
}

/* main */

.sideNavContainer {
    display: flex;
}

/* responsive design  */


@media (max-width:1024px) {
    .a2z_sidenav {
        // left: -300px;
    }

    .a2z_sidenav.active {
        width: 300px;
        left: 0;
    }

    .a2z_mainContent {
        width: 100%;
        left: 0;
    }

    .a2z_mainContent.active {
        left: 80px;
        transition: 0.5;
    }

    .cardBox {
        grid-template-columns: repeat(2, 1fr);
    }

    .chatbox,
    .notifications {
        min-width: 50px;
    }
}

@media (max-width : 768px) {
    .details {
        grid-template-columns: repeat(1, 1fr);
    }

    .recentOrders {
        overflow-x: auto;
    }

    .status.inProgress {
        white-space: nowrap;
    }

    .chatbox,
    .notifications {
        min-width: 50px;
    }

    .user {
        min-width: 40px;
    }
    .a2z_switchingContent {
        padding: 15px 20px;
    }
}

@media (max-width : 480px) {
    .cardBox {
        grid-template-columns: repeat(1, 1fr);
    }

    .cardHeader h2 {
        font-size: 20px;
    }

    .user {
        min-width: 40px;
    }

    .a2z_sidenav {
        width: 100%;
        left: -100%;
        z-index: 1000;
    }

    .a2z_sidenav.active {
        // width: 100%;
        width: 72px;
        position: fixed;
        left: 0;
        transition: 0.5;
    }

    .toggle {
        z-index: 10001;
    }

    .a2z_mainContent.active .toggle {
        position: fixed;
        right: 0;
        left: initial;
        color: var(--blue);
    }
    .a2z_switchingContent {
        padding: 15px 20px;
    }
}