@media (max-width: 480px) {

    .a2z_wedding_info_logo.active {
        width: 200px !important;
    }
    .mobile_view_darkmode{
        display: block;
    }
    .desktop_view_darkmode {
        display: none;
    }
    .desktop_view_sideTabs {
        display: none !important;
    }
    .mobile_view_sideTabs {
        display: block !important;
    }
    .a2z_sidenav.active .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI:nth-child(1) {
        margin-bottom: 90px !important;
        transition: 0.5s !important;
    }
    .a2z_sidenav.active .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI {
        width: 195px !important;
    }
    .last_link {
        margin-bottom: 100px;
    }
       
}