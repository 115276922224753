// .a2z_sidenav {
//     position: fixed;
//     width: 340px;
//     height: 100%;
//     background-color: var(--white);
//     border-left: 10px solid var(--white);
//     transition: 0.5s;
//     overflow: hidden;
// }
.s100_sideNavUL {
    max-height: 100%; /* Set your desired maximum height */
    overflow-y: auto;
    overflow-x: hidden;
  }
  .s100_sideNavUL::-webkit-scrollbar {
    width: 0px; /* Set your desired width */
  }
  
.mobile_view_darkmode{
    display: none;
}
.mobile_view_sideTabs {
    display: none !important;
}

.a2z_sidenav {
    position: fixed;
    width: 220px;
    height: 100%;
    background-color: var(--sidenav-bg-clr);
    border-left: 10px solid var(--sidenav-bg-clr);
    transition: 0.5s;
    overflow: hidden;
}
.sidenav_icon {
    // filter: var(--icons-active-clr) !important;
    width: 50px !important;
    background: #FFF  !important;
    padding: 2px !important;
    border-radius: 10px !important;
}

.svg_icon {
    filter: var(--icons-active-clr) !important;
}

.a2z_sidenav.active {
    width: 80px;  
    transition: 0.5s;
}

.s100_sideNavContainer {
    display: flex;
}

.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // height: 85vh !important;
    // overflow-x: auto;
    // box-shadow: 0 7px 25px #00000014;
}

.s100_iconTitle {
    display: flex;
    align-items: center;
    gap: 10px;
}
.s100_addDownArrow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.a2z_sidenav.active .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI:nth-child(1) {
    margin-bottom: -50px !important;
    transition: 0.5s;
}

.a2z_sidenav.active .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI {
    width: 60px;
}

.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI {
    position: relative;
    width: 195px;
    border-radius: 10px;
    list-style: none;
    margin: 1px 0;
    // border-top-left-radius: 30px;
    // border-bottom-left-radius: 30px;
}

.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI:hover,
.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI.hovered {
    transition: 0.5s;
    background: var(--hover-bg-clr);
    // border-radius: 52px;
    color: var(--hover-clr);
}

.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI:hover .s100_sideNavAnchor .s100_icon {
    line-height: 96px !important;
}

.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI.active .s100_sideNavAnchor .s100_icon {
    line-height: 96px !important;
}

.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI:hover .s100_sideNavAnchor .s100_iconTitle .s100_icon .sidenav_icon {
    // filter: var(--icons-hover-clr) !important;
    width: 45px !important;
    height: 45px !important;
}

.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI.active {
    background: var(--hover-bg-clr);
    // border-radius: 52px !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: -0.01em;
    color: var(--active-text-clr) !important;
    // width: 78px;
    // height: 24px;
    margin: 1px 0;
}

.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI.active .s100_sideNavAnchor .s100_iconTitle .s100_icon .sidenav_icon {
    // filter: var(--icons-active-clr) !important;
    width: 45px !important;
    transition: 0.5s;
    height: 45px !important;
}

.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI.active .s100_sideNavAnchor .s100_title {
    color: var(--active-text-clr) !important;
}


.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI:nth-child(1),
.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI:nth-child(1) .s100_sideNavAnchor {
    margin-bottom: 80px !important;
    margin-top: 5px !important;
    // pointer-events: none;
    color: var(--blue);
    padding-left: 19px;
    transition: 0.5s;
    background: transparent;
}
.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI .s100_sideNavAnchor {
    position: relative;
    display: block;
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    font-size: 15px;
    line-height: 24px;
    color: #FFF;
    font-weight: 600;
}

.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI:hover .s100_sideNavAnchor,
.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI.hovered .s100_sideNavAnchor {
    color: var(--hover-clr);
}

.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI:hover .s100_sideNavAnchor .s100_icon,
.a2z_sidenav .s100_sideNavUL .s100_sideNavLI.hovered .s100_sideNavAnchor .s100_icon {
    color: var(--blue);
}

.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI .s100_sideNavAnchor .s100_icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    height: 60px;
    // line-height: 90px;
    text-align: center;
}

.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI .s100_sideNavAnchor .s100_icon ion-icon {
    font-size: 1.75em;
}

.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI .s100_sideNavAnchor .s100_addIcon {
    position: relative;
    display: block;
    padding-right: 20px;
    height: 60px;
    line-height: 73px;
    text-align: center;
}

.a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI .s100_sideNavAnchor .s100_CheveronIcon {
    position: relative;
    display: block;
    padding-right: 20px;
    height: 60px;
    line-height: 68px;
    text-align: center;
}

// .a2z_sidenav .s100_sideNavContainer .s100_sideNavUL .s100_sideNavLI .s100_sideNavAnchor .s100_title {
//     // font-weight: 600;
//     // font-size: 15px;
//     // line-height: 24px;
//     // letter-spacing: -0.01em;
// }

// darkmode settings container styles in sidenav bar

.s100_modes {
    padding: 10px 12px;
    margin: 5px 0px;
    cursor: pointer;
    transition: 0.5s;
}
.s100_active_modes {
    padding: 8px;
    cursor: pointer;
    background: var(--dl-hover-bg-clr);
    border-radius: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.s100_modes:hover {
    background: var(--dl-hover-bg-clr );
    border-radius: 52px;
    color: var(--sidenav-clr);
}


.s100_dark_theme_settings {
    position: fixed;
    bottom: 20px;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.5s;
}
.s100_dark_theme_settings_icons {
    position: fixed;
    bottom: 5px;
    left: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.5s;
}

.s100_lightTitle, .s100_darkTitle {
    padding-left: 0px;
}
.s100_lightTitle {
    font-weight: bold;
    font-size: 14px;
}
.s100_info_icon {
    margin-left: 0px;
}

.s100_helpSettingsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    width: 190px;
    margin: 7px 0px;
    transition: 0.5s;
    border-top: 3px #F3F3F3 solid;
}

.s100_helpSettings {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #6F767E;
}

.s100_settingsBadge {
    width: 24px;
    padding: 3px 7px;
    height: 24px;
    background: #CABDFF;
    font-weight: bold;
    border-radius: 6px;
}
.s100_toggleBtn {
    display: flex;
    justify-content: space-evenly;
    background: #F3F3F3;
    border-radius: 30px;
    width: 190px;
    height: 40px;
    transition: 0.5s;
}

.s100_active_btn {
    background: #FCFCFC;
    // box-shadow: 0px 4px 8px -4px rgb(0 0 0 / 25%), inset 0px -1px 1px rgb(0 0 0 / 4%), inset 0px 2px 0px rgb(255 255 255 / 25%);
    border-radius: 32px;
    margin: 4px;
    display: flex;
    gap: 2px;
    width: 85px;
    font-weight: bold;
    height: 32px;
    align-items: center;
    padding: 3px 7px;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.8);
    cursor: pointer;
}
.s100_mode_btn {
    border-radius: 32px;
    cursor: pointer;
    background: transparent;
    margin: 4px;
    display: flex;
    gap: 2px;
    width: 85px;
    height: 32px;
    align-items: center;
    padding: 3px 7px;
    color: #6F767E;
    font-weight: bold;
    
}

.s100_mode_btn:hover {
    background: #FCFCFC;
    // box-shadow: 0px 4px 8px -4px rgb(0 0 0 / 25%), inset 0px -1px 1px rgb(0 0 0 / 4%), inset 0px 2px 0px rgb(255 255 255 / 25%);
    border-radius: 32px;
    margin: 4px;
    cursor: pointer;
    color: #000;
    display: flex;
    width: 85px;
    font-weight: bold;
    height: 32px;
    align-items: center;
    padding: 3px 7px;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.4);

}

.a2z_wedding_info_logo {
    width: 120px;
    transition: 0.5s;
    margin-top: 65px;
    margin-left: -15px;
    border-radius: 50%;
}
.a2z_wedding_info_logo.active {
    width: 60px;
    transition: 0.5s;
    margin-top: 10px;
    margin-left: -76px;
}
.last_link {
    margin-bottom: 100px;
}

