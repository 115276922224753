.active_badge {
    padding: 5px 8px;
    background: #EAFAE4;
    border-radius: 4px;
    color: #83BF6E;
    margin-right: 5px;
    width: fit-content;
}
.inactive_badge {
    padding: 5px 8px;
    background: #FFE7E4;
    border-radius: 4px;
    color: #FF6A55;
    margin-right: 5px;
    width: fit-content;
}
.circle_active_badge {
    padding: 5px 8px;
    background: #EAFAE4;
    border-radius: 50%;
    color: #83BF6E;
    margin-right: 5px;
}
.circle_gray_badge {
    padding: 1px 8px;
    background: gray;
    border-radius: 50%;
    color: #fff;
    margin: 0 5px;
}
.circle_red_badge {
    padding: 5px 8px;
    background: #FF6A55;
    border-radius: 50%;
    color: #fff;
    margin-right: 5px;
    cursor: pointer;
}
.circle_green_badge {
    padding: 5px 8px;
    background: #83BF6E;
    border-radius: 50%;
    color: #fff;
    margin-right: 5px;
    cursor: pointer;
}


.redNotification {
    padding: 5px 8px;
    background: #FFE7E4;
    border-radius: 4px;
    color: #FF6A55;
    margin-right: 5px;
    width: fit-content;
    position: relative;
}
.grayNotification {
    padding: 5px 8px;
    background: #ebebeb;
    border-radius: 4px;
    color: #808080;
    margin-right: 5px;
    width: fit-content;
    position: relative;
}
  
.greenNotification {
    padding: 5px 8px;
    background: #EAFAE4;
    border-radius: 4px;
    color: #83BF6E;
    margin-right: 5px;
    width: fit-content;
    position: relative;
}

  
.greenNotification .red_small_badge {
    position: absolute;
    top: -8px;
    right: -7px;
    padding: 2px 6px;
    font-size: 14px;
    border-radius: 50%;
    background: #FF6A55;
    color: #fff;
    cursor: pointer;
}

.redNotification .red_small_badge {
    position: absolute;
    top: -8px;
    right: -7px;
    padding: 2px 6px;
    font-size: 14px;
    border-radius: 50%;
    background: #FF6A55;
    color: #fff;
    cursor: pointer;
}
.greenNotification .green_small_badge {
    position: absolute;
    top: -8px;
    right: -7px;
    padding: 3px 6px;
    font-size: 14px;
    border-radius: 50%;
    background: #83BF6E;
    color: white;
    cursor: pointer;
}

.yellowBadge {
    padding: 5px 8px;
    background: #c5c57a;
    border-radius: 4px;
    color: #000;
    margin-right: 5px;
    width: fit-content;
    position: relative;
    border: 2px #ebebeb solid;
    cursor: pointer;
}
.yellowBadge:hover {
    background: #c5c57a;
}

