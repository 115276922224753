

.cp {
    cursor: pointer !important;
}

.hrg {
    background: var(--bg-clr) !important;
    opacity: 0.2;
    border-radius: 8px;
    margin: 30px 0;
}

.alert-red {
    color: red;
    font-size: 16px;
}

.fs16 {
    font-size: 16px;
}

.grayText {
    color: #6f767e ;
}

.text_event {
    color: #2A85FF  !important;
    font-weight: 700 !important;
}


.select-menu{
    width: 150px;
}
.select-menu .select-btn{
    display: flex;
    height: 55px;
    background: var(--bg-clr);
    padding: 20px;
    font-size: 15px;
    font-weight: 700;
    border-radius: 8px;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
}
.select-btn i{
    font-size: 25px;
    transition: 0.3s;
}
.select-menu.active .select-btn i{
    transform: rotate(-180deg);
}
.select-menu .options{
    position: absolute;
    padding: 15px;
    margin-top: 10px;
    border-radius: 8px;
    background: var(--bg-clr);
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.3);
    display: none;
}
.select-menu.active .options{
    display: block;
}
.options .option{
    display: flex;
    height: 55px;
    cursor: pointer;
    border-radius: 8px;
    align-items: center;
    // background: #fff;
    background: var(--bg-clr);;
    margin: 5px 0;
}
.options .option:hover{
    background: var(--text-clr);
    color: var(--dropdown-text-color);
}
.option i{
    font-size: 25px;
    margin-right: 12px;
}
.option .option-text{
    font-size: 15px;
    padding: 20px;
    // color: #333;
}
 
// .uuid_container:hover {
//     // color: #2A85FF;
// }
.uuid_container:hover .copy_icon {
    color: #2A85FF;
    visibility: visible;
    filter: var(--copy-icons-hover);
}

.copy_icon {
    cursor: pointer;
    visibility: hidden;
    filter: var(--copy-icons-hover);
}

.copy_icon:hover {
    scale: 1.2;
}

.icon_bg_gray {
    border-radius: 50%;
    background:  var(--action-bg--clr) !important;
    padding: 6px !important;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    cursor: pointer;
    transition: 0.1s;
}
.icon_bg_gray:hover {
    scale:1.1;
    transition: 0.1s;
}
.icon_bg_red {
    border-radius: 10px;
    background:  var(--delete-modal-icon-bg) !important;
    padding: 8px !important;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    cursor: pointer;
    transition: 0.1s;
}
.icon_bg_red:hover {
    scale:1.1;
    transition: 0.1s;
}

.width100 {
    width: 100% !important;
}



