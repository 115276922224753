@media (max-width: 480px) {
    .overview_content {
        overflow-x:scroll;
        scroll-snap-type: x mandatory;
        width: 100%;
    }
    .overview_content {
        padding: 5px 0px;
    }
    .overview_card {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    } 

    .overview_content  .overview_card {
        flex: 0 0 90%;
        padding: 24px;
        scroll-snap-align: start;
    }
    .subtitle {
        margin-top: 5px;
        font-size: 13px !important;
    }
    .gridContainer {
        display: grid;
        grid-template-columns: 1fr;
    }
    .vender-card {
        display: flex;
        width: -webkit-fill-available;
        align-items: center;
        position: relative;
        border: 1px solid #f1f1f1;
        border-radius: 10px;
        padding: 10px;
        gap: 10px;
    }
    .iconGrid {
        display: flex;
        flex-direction: column;
        text-align: left !important;
        width: 100%;
        /* height: 100px; */
        margin: 10px 0;
    }
    
}