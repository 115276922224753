.vender-card {
  display: flex;
  width: 250px;
  align-items: center;
  position: relative;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
}

.vender-card:hover {
  background:#f0394d  ;
  color: #FFF;
}
.vender-card:hover .vender-name {
  color: #c1b4b4;
}
.vender-card:hover .vender-title {
  color: #FFF;
}
.vender-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #F1F1F1;
}
.vender-count {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #f3f4f6;
  padding: 5px 10px ;
  border-radius: 10px;
  font-size: 12px;
  line-height: 1rem;
  color: #1f2937;
}
.vender-title {
  width: 100px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--card-clr);
}
.vender-name {
  color: #6b7280;
  font-size: .875rem;
  line-height: 1.25rem;
}


.m200_container {
    margin-top: 10px;
}
.m200_dashboard_container {
    display: flex;
    width: 100%;
    gap: 10px;
}
.m200_left_Container {
    width: 100%;
}
.m200_right_Container {
    width: 30%;
}

.card1 {
    height: 400px;
}

h1,
h3,
h4,
h5 {
    margin: 0px;
    padding: 0px;
}

/* overview styles */

.overview {
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
    margin: 10px 0px;
    background: #fff;
    border-radius: 10px;
    width: max-content;
}

.overview_header {
    font-size: 20px;
    font-weight: 900;
    border-left: 4px #4343e4 solid;
    padding: 6px 0px 6px 15px;
    margin: 5px 10px;
}

.overview_content {
    display: flex;
    padding: 10px 0px;
}

.overview_card_titles {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #FFF;
    margin: 5px -20px !important;
}
.overview_card {
    margin: 4px 10px;
    // padding: 30px 44px;
    padding: 16px 30px;
    height: max-content;
    // width: 100%;
    border-radius: 10px;
}

.card1 {
    background-color: #e9fce9;
}

.card2 {
    background-color: #dcf1ff;
}

.card3 {
    background-color: rgb(248, 237, 248);
}

.active_online {
    background-color: #e9fce9;
}

.total_normal {
    display: flex;
    background-color: var(--bg-clr);
    color: #FFF;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.inactive_offline {
    background-color: #f8edf8;
}

/* .overview_card h1 {
    font-size: 40px;
} */

.overview_card h5 {
    background: #fff;
    font-size: 15px;
    display: flex;
    padding: 5px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    color: gray;
    width: max-content;
    margin: 0 !important;
}

.overviewPercentage {
    color: #83bf6e;
    padding: 0 5px;
    margin-top: 10px;
}
.wedding_date {
    background: #FFF;
    padding: 5px;
    margin-top: 5px;
    border-radius: 5px;
    color: #83bf6e;
}

/* channels styles */

.channel {
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
    margin: 10px 0px;
    background: #fff;
    border-radius: 10px;
    width: max-content;
    height: max-content;
}

.channel_header_top {
    display: flex;
}

.channel_header {
    font-size: 20px;
    font-weight: 900;
    border-left: 4px #00a33f solid;
    padding: 6px 0px 6px 15px;
    margin: 5px 25px 5px 5px;
}

.channel_row_sub_heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    // line-height: 24px;
    letter-spacing: -0.01em;
    color: var(--text-clr);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.channel_content {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
}

.channel_row_content {
    display: flex;
    align-items: center;
    margin: 12px 0px;
}

.channel_row_sub_content {
    margin-left: 10px;
}

.channel_row_sub_content h5 {
    color: var(--text-clr);
}

/* Activity styles */

.activity {
    display: flex;
    flex-direction: column;
    padding: 15px 25px;
    margin: 10px 0px;
    background: #fff;
    border-radius: 10px;
    width: max-content;
    height: max-content;
}

.activity_header_top {
    display: flex;
}

.activity_header {
    font-size: 20px;
    font-weight: 900;
    border-left: 4px #e05312 solid;
    padding: 6px 0px 6px 15px;
    margin: 5px 25px 5px 5px;
}

.activity_dropdown {
    align-items: flex-end;
}

.activity_content {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
}

.activity_row_content {
    display: flex;
    margin: 15px 0px;
    align-items: center;
}

.activity_row_sub_content h4 {
    color: gray;
    padding-left: 10px;
}

.activity_row_sub_content h4 span {
    color: var(--text-clr);
    font-weight: 900;
}

.dashboard_grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.video_tutorials {
    display: flex;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    padding: 30px;
}

.video_tutorials_heading {
    margin: 10px;
    text-align: center;
}

.video_tutorials_subHeading {
    margin: 10px;
    text-align: center;
}

.dashboard_grid-container {
    /* display: grid;
    grid-template-columns: auto auto;
    gap: 10px; */
    display: grid;
    grid-template-columns: repeat(1, 1fr) 400px;
    gap: 10px;
    /* grid-template-rows: 318px 384px 672px 456px 460px; */
}

.dashboard_grid-item {
    margin: 5px;
}
.whiteBtnFluid {
    padding: 12px 20px;
    background-color: white;
    font-weight: 900;
    border: 3px #f1f1f1 solid;
    border-radius: 12px;
    width: 100%;
}
.lightGrayBtn {
    padding: 12px 20px;
    background-color: #f1f1f1;
    font-weight: 900;
    border: 3px #e7e7e7 solid;
    border-radius: 12px;
    cursor: pointer;
}

.overViewCard_avatar {
    position: relative;
    width: 150px;
    border-radius: 50%;
    height: 150px; 
    // padding-bottom: 15px;
    border: 5px solid #FFF;
    overflow: hidden;
    cursor: pointer;
}
.white_heart {
    color: #FFF !important;
}

.overviewTitle {
    display: flex;
    align-items: center;
    gap: 5px;
}

.overviewAmount {
    color: #000;
    font-size: 40px;
    margin: 0 !important;
}


.d_icons {
    background: linear-gradient(89.92deg, #2a85ff1a 0.07%, #2a85ff1a 99.94%);
    padding: 15px;
    border-radius: 50%;
    display: flex;
    filter: invert(20);
}

// responsive


// new styles

.iconGrid {
    display: flex;
    flex-direction: column;
    // text-align: center;
    width: 100px;
    height: 100px;
    margin: 15px;
    /* box-shadow: 0 0 20px 2px rgba(0, 0, 0, .1); */
    transition: 1s;
  }
  
  .iconGrid:hover {
    transform: scale(1.1);
    cursor: pointer;
    z-index: 2;
  }
  
  .gridContainer {
    // background:var(--bg-clr);
    display: grid;
    grid-template-columns: repeat(auto-fit, 19.5rem);
    // justify-content: center;
    /* margin: 30px; */
    /* padding: 10px 0 50px; */
    border-radius: 20px;
    padding-bottom: 20px;
    width: 100%
  }
  
  .navContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, 10rem);
    justify-content: center;
    bottom: 0;
    width: 100%;
    position: fixed;
    z-index: 1;
  }
  
  .menuGrid {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 50px;
    height: auto;
    margin: 10px;
  
  }
  
  .icons {
    border-radius: 20%;
    background: #FFF;
    width: 80px;
    height: 80px;
  }
  
  .iconText {
    color: white;
    font-size: 15px;
    font-weight: 700;
  }
  
  .iconLink {
    text-decoration: none;
  }
  
  
  /* #invitation  */
  
  .weddingInviteCards {
    display: flex;
  }
  
  .weddingInviteCard {
    margin-right: 35px;
  }
  
  .pageHeader {
    text-align: center;
  }
  
  .noContentHeaders {
    background-color: #282c34;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .pageContainers {
    margin: 25px;
  }
  
  /* #callCenter */
  
  .callCenterHead {
    display: flex;
  }
  
  .singleCustomerCare {
    margin-right: 35px;
    text-align: center;
  }
  
  /* #contacts */
  
  .leftIconGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;
    height: 100px;
    margin: 20px;
  }
  
  .contactContent {
    display: grid;
    grid-template-columns: repeat(auto-fit, 15rem);
    justify-content: center;
    margin: 30px;
  
  
  }
  
  .navLeft {
    float: left;
    cursor: pointer;
  }
  
  /* LandingPage */
  
  .LandingPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .homelogo {
    transition: 1s;
  }
  
  .logo {
    width: 200px;
    border-radius: 50%;
  }
  
  .homelogo:hover {
    transform: scale(1.3);
    z-index: 1;
  }
  
  .WorkInProgress {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
  }
  
  .commingSoon:hover {
    transform: scale(1.6);
    z-index: 1;
  }
  
  .main_title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 48px;
    flex-direction: column;
  }
  .sub_title {
    font-size: 24px;
  }
  
  .card_title {
    font-size: 34px;
    padding: 5px 15px;
  }
  
  .commingSoon {
    transition: 2s;
  }
  
  .home_icon {
    height: 100px;
    width: 100px;
  }
  
  
  .main_landing_card {
    display: flex;
    height: 200px;
    width: 500px;
    background: black;
    padding: 50px;
    border-radius: 30px;
  }
  
  @media (max-width : 480px) {
    .gridContainer {
      display: grid;
      grid-template-columns: repeat(auto-fit, 10rem);
      justify-content: center;
      margin: 10px;
    }
    .commonheader {
        display: none;
    }
  
    .flag {
      width: 200px;
    }
  
    .groomBride {
      width: 100px;
    }
  
  }
  
  @media (max-width : 768px) {
    .gridContainer {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;
      align-items: center;
     
    }
  
    .iconGrid {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100px;
      height: 100px;
      margin: 25px 0;
      /* box-shadow: 0 0 20px 2px rgba(0, 0, 0, .1); */
      transition: 1s;
    }
  
    .icons {
      border-radius: 20%;
      background: #FFF;
      width: 60px;
      height: 60px;
    }
  
    .iconText {
      color: white;
      font-size: 15px;
      font-weight: 700;
    }
    .sub_title {
      font-size: 14px;
  }
  .main_title {
    font-size: 28px;
  }
  .search_container {
    background: #000;
    color: #FFF;
    /* width: 100%; */
    /* height: 50px; */
    margin: 10px !important;
    padding: 10px 0 15px !important;
    border-radius: 20px;
  }
  .card_title {
    font-size: 16px;
    padding: 5px 15px;
  }
  .wedding_invitations {
    background: #000;
    color: #FFF;
    /* width: 100%; */
    /* height: 100px; */
    margin: 10px !important;
    padding: 10px 0 5px !important;
    border-radius: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .past_wedding {
    flex-direction: column;
  }
  
  }
  
  
  
  /* new styles */
  
  .search_container {
    background: #000;
    color: #FFF;
    /* width: 100%; */
    /* height: 50px; */
    margin: 30px;
    padding: 10px 0 20px;
    border-radius: 20px;
  }
  
  .wedding_invitations {
    background: #000;
    color: #FFF;
    /* width: 100%; */
    /* height: 100px; */
    margin: 30px;
    padding: 10px 0 50px;
    border-radius: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .past_wedding {
    display: flex;
    
  }
  
  .invite_iconGrid {
    display: flex;
      flex-direction: column;
      text-align: left;
      /* width: 300px; */
      /* height: 100px; */
      margin: 10px;
      /* box-shadow: 0 0 20px 2px rgba(0, 0, 0, .1); */
      transition: 1s;
  }
  
  .invite_card {
    display: flex;
    /* width: 400px; */
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
  }

  .invitation_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, 24rem);
    /* margin: 30px; */
    /* padding: 10px 0 50px; */
    border-radius: 20px;
    padding-bottom: 20px;
    width: 100%;
  }





