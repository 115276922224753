@import 'react-toastify/dist/ReactToastify.css';
@import 'react-loading-skeleton/dist/skeleton.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter';
  font-style: normal; 
}

.asterisk::before {
  content:"*";
  color: red;
  font-size: 18px;
}
.coming_soon {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0394d;
  border-radius: 20px;
  color: #FFF;
  margin: 10px;
  font-size: 24px;
}

:root {
  --lightBlue: #f5fcff;
  --blue: #287bff;
  --white: #fff;
  --grey: #f5f5f5;
  --black1: #222;
  --black2: #807b7b;
  --gray: #f1f1f1;
}

.pluse_btn {
  margin-right: 5px;
}

// modal styles

.blurring.dimmable>.dimmer {
  background: rgba(121, 158, 207, 0.33) !important;
}

.ui.modal {
    width: 647px !important;
    border-radius: 30px !important;
    background: var(--modal-bg-clr) !important;
    color: var(--modal-clr) !important;
}

.ui.segment {
  background: var(--modal-bg-clr) !important;
  color: var(--modal-clr) !important;
}

.ui.form .field>label , .ui.checkbox label {
  color: var(--text-clr) !important;  
}


.content {
  border-radius: 30px !important;
}

.modal_sub_heading {
  font-size: 20px;
  display: block;
  text-transform: uppercase;
  margin: 20px 0;
}

.delete_icon {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.unassign-user {
  width: 80px;
  height: 80px;
  object-fit: cover;
}


@media (max-width: 480px) {
  .ui.modal {
    width: 350px  !important;
  }
  .unassign-user {
    width: 65px;
    height: 65px;
  }
  .ui .page .modals .dimmer .transition .visible .active  {
    display: flow-root !important;
  }
  .modal_content {
    padding: 25px 15px !important;
}
.buttonGroup {
  margin-top: 10px !important; 
}
.addCustomerHeading {
  text-align: center !important;
}

.addCustomerHeading {
  font-size: 28px !important;
  line-height: 30px !important;
  border-bottom: 2px solid var(--modal-text-clr) !important;
  letter-spacing: -0.03em !important;
  padding-bottom: 10px !important;
  margin-bottom: 10px !important;
  color: var(--modal-text-clr);
}

// advance search 

.filter_container .advance_search_container {
  flex-direction: column-reverse;
  gap: 15px;
  align-items: flex-start !important;
}
.advanceSearchBtn {
  margin: 0 !important;
}
.filledI_right, .filledI_down {
  height: 20px !important;
}
.filter_container .advance_search_container .advanceSearchBtn {
  width: 100%;
  justify-content: space-between;
}

.commonheader {
  font-size: 24px !important;
  margin-left: 5px;
}
 
}
@media (max-width: 1024px) {
 
}

@keyframes blink-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.blink-image {
  animation: blink-animation 2s infinite;
}

.invite-btn {
  background: var(--bg-clr);
  padding: 13px 10px;
  color: #fff;
  font-weight: 900;
  border: none;
  border-radius: 14px;
  cursor: pointer;
}