.sl_circle_image {
    width: 90px;
    border-radius: 50%;
    height: 90px;
}
.sl_circle_image30 {
    width: 30px;
    border-radius: 50%;
    height: 30px;
    margin: 0 5px;
}
.sl_circle_image40 {
    width: 40px;
    border-radius: 50%;
    height: 40px;
    margin: 0 5px;
}
.sl_squre_img80 {
    width: 80px;
    border-radius: 10px;
    height: 80px;
}
.sl_squre_image30 {
    width: 30px;
    border-radius: 10px;
    height: 30px;
    margin: 0 10px;
}
.sl_squre_image40 {
    width: 40px;
    border-radius: 10px;
    height: 40px;
    margin: 0 10px;
}
.sl_count {
    width: 50px;
    // border-radius: 50%;
    height: 30px;
    margin: 15px 0;
}
.all_channels_count {
    width: 100px;
    border-radius: 10px;
    height: 15px;
}

.sl_small_line {
    width: 150px;
}



.sl_s_badge {
    width: 165px;
    height: 26px;
}

.sl_id_bars {
    width: 100px;
}

// product page 

.sl_series {
    width: 80px;
    height: 30px;
}

.sl_badge_7010 {
    width: 70px;
    height: 10px;
}

.sl_line_10010 {
    width: 100px;
    height: 10px;
}



// dealer 






// for all

.sl_checkbox_40 {
    width: 20px;
    height: 15px;
}
.sl_small_badge {
    width: 100px;
    height: 15px;
}


// dealers info 


.sl_input_bar {
    height: 45px;
    width: 100%;
    border-radius: 10px;
}
.sl_textarea_bar {
    height: 100px;
    width: 100%;
    border-radius: 10px;
}

// staff

.sl_line_8020 {
    width: 80px;
    height: 20px;
}


// maps

.sl_line_4020 {
    width: 40px;
    height: 20px;
}

.sl_maps {
    height: 100vh;
}


//domain page

.sl_domain_count {
    width: 50px;
    margin-right: 20px;
    margin-bottom: 20px;
    height: 45px;
}
.sl_domain_url {
    width: 300px;
    height: 45px;
}

// device maps 
.sl_device_map_pagination {
    height: 200px;
}


.sl_profile {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 30px;
}

.sl_topnav_profile {
    width: 50px;
    height: 50px;
    margin-right: 30px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}


// file 

.sl_specific_file {
    border-radius: 10px;
    height: 150px;
    width: 150px;
    display: flex;
    flex-direction:column ;

}
.sl_close-circle {
    position: absolute;
    right: -13px;
    top: -18px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.sl_file_name {
    width: 140px;
    margin: 0 5px !important;
}



@media (max-width : 780px) {
    .sl_profile {
        margin-right: 0;
    }
}