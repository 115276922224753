@media (max-width: 768px) {

    .role_email_box {
        display: none;
    }
    
    .s200_search label input {
        width: 96%;
    }
    
    .role_box {
        display: flex;
        flex-direction: column;
    }
    .a2z_mainContent.active {
        // width: calc(100% - 220px) !important;
        // left: 220px !important;
        filter: blur(5px) grayscale(.7);
        background: var(--sidenav-blur-bg-clr);
    }
    .a2z_mainContent {
        width: 100% !important;
        left: 0 !important;
    }
    .a2z_sidenav.active {
        width: 220px !important;
        transition: 0.5s !important;
    }
    .a2z_sidenav {
        width: 0px !important;
        transition: 0.5s;
    }
    
}